import React, { useEffect, useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import chevronDown from "../../../assets/svg/chevron-down.svg";

import cs from "classnames";

import "./MonthPicker.scss";
import { useLocation } from "react-router-dom";

interface MonthPickerProps {
  label: string;
  sendBackMonth: (month: number) => void;
}

const MonthPicker = (props: MonthPickerProps) => {
  const { label, sendBackMonth } = props;
  const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<null | string>(null);
  const monthPickerRef = useRef(null);
  const lang = localStorage.getItem("lang");
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  const location = useLocation();

  const monthList = [
    `1 ${lang === "EN" ? "month" : "muaj"}`,
    `2 ${lang === "EN" ? "months" : "muaj"}`,
    `3 ${lang === "EN" ? "months" : "muaj"}`,
    `4 ${lang === "EN" ? "months" : "muaj"}`,
    `5 ${lang === "EN" ? "months" : "muaj"}`,
    `6 ${lang === "EN" ? "months" : "muaj"}`,
    `7 ${lang === "EN" ? "months" : "muaj"}`,
    `8 ${lang === "EN" ? "months" : "muaj"}`,
    `9 ${lang === "EN" ? "months" : "muaj"}`,
    `10 ${lang === "EN" ? "months" : "muaj"}`,
    `11 ${lang === "EN" ? "months" : "muaj"}`,
    `12 ${lang === "EN" ? "months" : "muaj"}`,
  ];

  useOutsideClickDetection(monthPickerRef, () => {
    setIsMonthPickerOpen(false);
  });

  useEffect(() => {
    const jobStorageMonth = jobStorage?.schedule?.length_in_months;
    if (jobStorageMonth && location.pathname === "/jobs/edit-long-term-job") {
      const matchMonth = monthList.find((item) =>
        item.startsWith(jobStorageMonth.toString()),
      );
      setSelectedMonth(matchMonth || null);
      sendBackMonth(jobStorageMonth);
    }
  }, []);

  return (
    <div className="MonthPicker" ref={monthPickerRef}>
      <div
        className="MonthPicker__head"
        onClick={() => {
          setIsMonthPickerOpen(!isMonthPickerOpen);
        }}
      >
        <div className="MonthPicker__head-container">
          <div
            className={cs(
              "MonthPicker__head-label",
              selectedMonth !== null && "MonthPicker__head-label--active",
            )}
          >
            {label}
          </div>
          <div className="MonthPicker__head-value">{selectedMonth}</div>
        </div>
        <img
          alt="pic"
          src={chevronDown}
          className="MonthPicker__head-chevron"
        />
      </div>
      {isMonthPickerOpen && (
        <div className="MonthPicker__body">
          <div className="MonthPicker__body-container">
            {monthList.map((month, index) => (
              <div
                className="MonthPicker__body-item"
                key={index}
                onClick={() => {
                  const splitMonth = month.split(" ");
                  const monthNumber = Number(splitMonth[0]);
                  setSelectedMonth(month);
                  setIsMonthPickerOpen(false);
                  sendBackMonth(monthNumber);
                }}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthPicker;
