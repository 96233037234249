import React, { useEffect, useState, useRef } from "react";
import LoadingComponent from "../../../../components/shared/LoadingComponent/LoadingComponent";
import AttendanceCard from "../AttendanceCard/AttendanceCard";
import RightSidebar from "../../../../components/shared/RightSidebar/RightSidebar";
import AttendanceProfile from "../AttendanceProfile/AttendanceProfile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cs from "classnames";
import chevronLeft from "../../../../assets/svg/chevron-down-gray.svg";
import { apiCall } from "../../../../api/Api";
import reloadIcon from "../../../../assets/svg/Refresh_icon.svg";

import "./ShortTermAttendance.scss";
import ApplicationCard from "../../Applications/ApplicationCard/ApplicationCard";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

const ShortTermAttendance = () => {
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  let selectedLanguage = localStorage.getItem("lang");
  const [job, setJob] = useState(jobStorage);
  const [allApplications, setAllApplications] = useState<any>([]);
  const [error] = useState("");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRightSidebar, setOpenSidebar] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [refreshCalled, setRefreshCalled] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const rightSidebarRef = useRef(null);

  const hideRightSidebar = () => {
    setOpenSidebar(false);
    setSelectedApplicant({});
  };
  // useEffect(() => {
  //   if (!isCalled) {
  //     setIsLoading(true);
  //     apiCall("GET", `b2b/applications/${job.id}/`)
  //         .then((res) => {
  //           setAllApplications(res.data.results);
  //           // setNextPage(res.data?.next);
  //           setIsCalled(true);
  //           setIsLoading(false);
  //         })
  //         .catch((err) => {
  //           setIsLoading(false);
  //         });
  //   }
  // }, []);

  const refresh = () => {
    if (!isCalled) {
      setIsLoading(true);
      apiCall("GET", `b2b/applications/${job.id}/`)
        .then((res) => {
          setAllApplications(res.data.results);
          // setNextPage(res.data?.next);
          setIsCalled(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  useOutsideClickDetection(rightSidebarRef, () => {
    hideRightSidebar();
  });

  return (
    <section className="ShortTermAttendance">
      <div className="ShortTermAttendance__header">
        <div className="ShortTermAttendance__title-container">
          <h1 className="ShortTermAttendance__default-title">
            {t("jobs.title")}
          </h1>
          {isLoading ? (
            <div className="Jobs__spinner" />
          ) : (
            <img
              src={reloadIcon}
              alt="reload icon"
              className={cs(
                "ShortTermAttendance__reload-icon",
                refreshCalled && "ShortTermAttendance__reload-icon--disable",
              )}
              onClick={() => {
                if (!refreshCalled) {
                  refresh();
                  setRefreshCalled(true);
                  setTimeout(() => {
                    setRefreshCalled(false);
                  }, 10000);
                }
              }}
            />
          )}
        </div>
        <section className="ShortTermAttendance__container">
          <div
            onClick={() => navigate("/jobs/short-term-not-assigned")}
            className={cs(
              "ShortTermAttendance__container-item",
              "ShortTermAttendance__container-item--active",
            )}
          >
            {t("jobs.shortTermTabLabel")}
          </div>
          <div
            onClick={() => {
              navigate("/jobs/long-term-not-assigned");
            }}
            className={cs("ShortTermAttendance__container-item")}
          >
            {t("jobs.longTermTabLabel")}
          </div>
        </section>
        <div className="ShortTermAttendance__header-wrapper">
          <img
            alt="chevron left icon"
            src={chevronLeft}
            className="ShortTermAttendance__back-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <h2 className="ShortTermAttendance__title">
            {selectedLanguage === "SQ"
              ? `${job.job_title.title?.sq}`
              : `${job.job_title.title?.en}`}
          </h2>
        </div>
      </div>
      {!isLoading && allApplications?.length === 0 && (
        <div className="LongTermApplications__no-content">
          {t("jobs.applications.noContent")}
        </div>
      )}
      <div className="LongTermApplications__cards-wrapper">
        {allApplications &&
          allApplications.map((person: any, index: number) => (
            <div key={index} className="Applications__card">
              <ApplicationCard
                person={person}
                jobName={
                  selectedLanguage === "SQ"
                    ? job.job_title.title?.sq
                    : job.job_title.title?.en
                }
                setSelectedApplicant={setSelectedApplicant}
                setOpenSidebar={setOpenSidebar}
              />
            </div>
          ))}
      </div>
      {isLoading && allApplications.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
      {openRightSidebar && (
        <div>
          <RightSidebar
            onClose={() => hideRightSidebar()}
            sidebarRef={rightSidebarRef}
          >
            <AttendanceProfile
              onClose={() => hideRightSidebar()}
              job_id={job.id}
              user_id={selectedApplicant.applicant.id}
            />
          </RightSidebar>
        </div>
      )}
    </section>
  );
};

export default ShortTermAttendance;
