import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiCall } from "../../../api/Api";

import classNames from "classnames";
import plusIcon from "../../../assets/svg/plus-icon-white.svg";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ShortTermJobCard from "../ShortTermJobCard/ShortTermJobCard";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import reloadIcon2 from "../../../assets/svg/Refresh_icon.svg";

import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";

import "./LongTermNotAssigned.scss";
import LongTermJobCard from "../LongTermJobCard/LongTermJobCard";

interface FilteredJobs {
  [key: string]: [];
}

const LongTermNotAssigned = () => {
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [allAvailableJobs, setAllAvailableJobs] = useState<any | null>([]);
  const [nextPage, setNextPage] = useState<any>("");
  const [filteredJobs, setFilteredJobs] = useState<FilteredJobs>();
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobsCountLoading, setJobsCountLoading] = useState(false);
  const [notAssignedJobsCount, setNotAssignedJobsCount] = useState(0);
  const [assignedJobsCount, setAssignedJobsCount] = useState(0);
  const [error, setError] = useState("");
  const [sortedKeys, setSortedKeys] = useState([]);
  const [refreshCalled, setRefreshCalled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("common");
  const globalCtx = useGlobalContext();

  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  useEffect(() => {
    setJobsCountLoading(true);
    apiCall("GET", `b2b/long-term-jobs/?status=assigned&limit=0`)
      .then((res) => {
        setAssignedJobsCount(res.data.count);
      })
      .finally(() => {
        setJobsCountLoading(false);
      });
    apiCall("GET", `b2b/long-term-jobs/?status=available&limit=0`)
      .then((res) => {
        setNotAssignedJobsCount(res.data.count);
      })
      .finally(() => {
        setJobsCountLoading(false);
      });
  }, []);

  useEffect(() => {
    // Extract payment_session_id from the URL
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("payment_session_id");

    if (sessionId) {
      // API call to check the session status
      apiCall("GET", `b2b/job-payment/session-status/${sessionId}/`)
        .then((response) => {
          // Check the response status and show modals accordingly
          if (response.data.status === "open") {
            globalCtx.openMessageModal(
              "error",
              t("staticMessage.paymentError"),
            );
          } else if (response.data.status === "complete") {
            globalCtx.openMessageModal(
              "success",
              t("staticMessage.newJobPostedSuccess"),
            );
          }
          // Clean up the URL by removing payment_session_id
          searchParams.delete("payment_session_id");
          navigate(`?${searchParams}`, { replace: true });
        })
        .catch((error) => {
          // Handle errors and show error modal
          const errorMessage = convertRestApiErrorCodes(
            error?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
          searchParams.delete("payment_session_id");
          navigate(`?${searchParams}`, { replace: true });
        });
    }
  }, [location.search, navigate, t, globalCtx]); // Add necessary dependencies

  const filterDates = (datesArray: any) => {
    //groups jobs into same dates
    const filteredJobs: FilteredJobs = datesArray.reduce(
      (group: any, item: any) => {
        const date = new Date(item?.job_start_time);
        const isoDateKey = date.toISOString().split("T")[0];
        group[isoDateKey] = group[isoDateKey] ?? [];
        group[isoDateKey].push(item);

        return group;
      },
      {},
    );

    setFilteredJobs(filteredJobs);

    const sortedKeys2: any = Object.keys(filteredJobs).sort();
    setSortedKeys(sortedKeys2);
  };

  const callJobs = () => {
    setIsLoading(true);
    apiCall("GET", `b2b/long-term-jobs/?status=available&limit=10`)
      .then((res) => {
        setAllAvailableJobs(res.data.results);
        filterDates(res.data.results);
        setIsCalled(true);
        setIsLoading(false);
        setNextPage(res.data?.next);
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    if (!isCalled) {
      callJobs();
    }
  }, []);

  useEffect(() => {
    if (location?.state?.previousLocationPathname === "/jobs/new-job") {
      callJobs();
    }
  }, []);

  const loadMoreJobs = () => {
    if (nextPage !== null) {
      setIsLoading(true);
      let resultUrl = nextPage.split("?")[1];
      apiCall("GET", `b2b/long-term-jobs/?${resultUrl}`)
        .then((res) => {
          setAllAvailableJobs([...allAvailableJobs, ...res.data.results]);
          filterDates([...allAvailableJobs, ...res.data.results]);
          setIsCalled(true);
          setIsLoading(false);
          setNextPage(res.data?.next);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextPage,
    onLoadMore: loadMoreJobs,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className="LongTermNotAssigned">
      <div className="LongTermNotAssigned__header-container">
        <div className="LongTermNotAssigned__title-container">
          <div className="LongTermNotAssigned__main-title">
            {t("jobs.title")}
          </div>
          {isLoading ? (
            <div className="LongTermNotAssigned__spinner" />
          ) : (
            <img
              src={reloadIcon2}
              alt="reload icon"
              className={classNames(
                "LongTermNotAssigned__reload-icon",
                refreshCalled && "LongTermNotAssigned__reload-icon--disable",
              )}
              onClick={() => {
                if (!refreshCalled) {
                  callJobs();
                  setRefreshCalled(true);
                  setTimeout(() => {
                    setRefreshCalled(false);
                  }, 10000);
                }
              }}
            />
          )}
        </div>
        <div className="LongTermNotAssigned__button-wrapper">
          <div
            className="LongTermNotAssigned__button"
            onClick={() => {
              navigate("/jobs/new-job");
            }}
          >
            <div className="LongTermNotAssigned__button-round">
              <img
                alt="pic"
                src={plusIcon}
                className="LongTermNotAssigned__button-round--plus"
              />
            </div>
            <p className="LongTermNotAssigned__button-text">
              {t("jobs.addJobButton")}
            </p>
          </div>
        </div>
      </div>
      <section className="LongTermNotAssigned__container">
        <div
          onClick={() => {
            navigate("/jobs/short-term-not-assigned");
          }}
          className={classNames("LongTermNotAssigned__container-item")}
        >
          {t("jobs.shortTermTabLabel")}
        </div>
        <div
          onClick={() => {
            navigate("/jobs/long-term-not-assigned");
          }}
          className={classNames(
            "LongTermNotAssigned__container-item",
            "LongTermNotAssigned__container-item--active",
          )}
        >
          {t("jobs.longTermTabLabel")}
        </div>
      </section>
      <section className="LongTermNotAssigned__buttons-container">
        <div
          className={classNames(
            "LongTermNotAssigned__not-assigned-btn flex",
            "LongTermNotAssigned__not-assigned-btn--active",
          )}
          onClick={() => {
            navigate("/jobs/long-term-not-assigned");
          }}
        >
          {`${t("jobs.notAssigned.tabLabel")} `}{" "}
          {jobsCountLoading ? (
            <div className="LongTermNotAssigned__spinner" />
          ) : (
            `(${notAssignedJobsCount})`
          )}
        </div>
        <div
          className={classNames("LongTermNotAssigned__assigned-btn flex")}
          onClick={() => {
            navigate("/jobs/long-term-assigned");
          }}
        >
          {`${t("jobs.assigned.tabLabel")}`}{" "}
          {jobsCountLoading ? (
            <div className="LongTermNotAssigned__spinner" />
          ) : (
            `(${assignedJobsCount})`
          )}
        </div>
      </section>
      {allAvailableJobs?.length === 0 && isCalled && !loading && (
        <div className="LongTermNotAssigned__no-content">
          {t("jobs.notAssigned.noContent")}
        </div>
      )}
      {filteredJobs &&
        sortedKeys?.map((key, index) => (
          <div className="LongTermNotAssigned__timeline" key={index}>
            <div className="LongTermNotAssigned__timeline-container">
              <div className="LongTermNotAssigned__timeline-content">
                <div className="LongTermNotAssigned__timeline-dot">
                  <div className="LongTermNotAssigned__timeline-dot-weekday">
                    {daysOfTheWeek[new Date(key).getDay()]}
                  </div>
                  <div className="LongTermNotAssigned__timeline-dot-day">
                    {new Date(key).getDate()}
                  </div>
                </div>
                {filteredJobs &&
                  filteredJobs[key]?.map((job: any, index: number) => (
                    <div
                      key={index}
                      className="LongTermNotAssigned__job-wrapper"
                    >
                      <LongTermJobCard job={job} shiftTotal={true} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

      <div className="LongTermNotAssigned__infinite-scroll-wrapper">
        {nextPage && true && (
          <div ref={sentryRef}>
            <LoadingComponent />
          </div>
        )}
      </div>

      {isLoading && allAvailableJobs.length === 0 && (
        <div style={{ margin: "150px auto" }}>
          <LoadingComponent />
        </div>
      )}
    </div>
  );
};

export default LongTermNotAssigned;
