import React, { useState } from "react";
import person from "../../../assets/svg/person.svg";
import { getTimeBetweenDates } from "../../../lib/helpers/getTimeBetweenDates";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/shared/Button/Button";
import cs from "classnames";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import { apiCall } from "../../../api/Api";

import "./LongTermJobCard.scss";

const LongTermJobCard = (props: any) => {
  const { job, shiftTotal } = props;
  let selectedLanguage = localStorage.getItem("lang");
  let today = new Date();
  let tomorrow = today;
  tomorrow.setDate(tomorrow.getDate() + 1);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const maxAvailableTime = dayjs(job?.job_start_time).subtract(12, "hour");
  const currentTime = dayjs();
  const diff = maxAvailableTime?.diff(currentTime, "day", true);
  const days = Math.floor(diff);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [showJobPayment, setShowJobPayment] = useState(false);

  const getPaymentSession = () => {
    apiCall("GET", `b2b/job-payment/checkout/${job.id}/`)
      .then((res) => {
        const clientSecret = res.data.client_secret;
        setClientSecret(clientSecret); // Set the client secret for the payment
        setShowJobPayment(true); // Show the JobPayment component
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const daysArray =
    selectedLanguage === "EN"
      ? ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
      : ["HËN", "MAR", "MËR", "ENJ", "PRE", "SHT", "DIE"];

  return (
    <section className="LongTermJobCard">
      {showJobPayment && clientSecret && (
        <ModalContainer position="top">
          <JobPayment
            clientSecret={clientSecret}
            onCloseClick={() => setShowJobPayment(false)}
          />
        </ModalContainer>
      )}
      <Tooltip
        title={`${selectedLanguage === "SQ" ? `Numri i punëtorëve të nevojshëm: ${job.seats}` : `Number of workers needed: ${job.seats}`}`}
        placement="top"
      >
        <div className="LongTermJobCard__header">
          <h2 className="LongTermJobCard__job-title">
            {selectedLanguage === "SQ"
              ? `(${job.seats}) ${job.job_title.title?.sq}`
              : `(${job.seats}) ${job.job_title.title?.en}`}
          </h2>
          <div className="LongTermJobCard__job-price">
            {job.schedule.monthly_pay_rate_start}{" "}
            {job.schedule.monthly_pay_rate_end &&
              `- ${job.schedule.monthly_pay_rate_end}`}{" "}
            &euro;/ {t("jobs.longTermJobCard.month")}
          </div>
        </div>
      </Tooltip>
      <div className="LongTermJobCard__body">
        <div className="LongTermJobCard__city">
          {job.organization_unit?.city}
        </div>
        {!job?.is_active ? (
          <div
            className="LongTermJobCard__pay-button"
            onClick={() => {
              getPaymentSession();
            }}
          >
            {t("jobs.longTermJobCard.pay")}
          </div>
        ) : job?.status === "available" && job?.job_start_time ? (
          <div
            className={cs(
              "LongTermJobCard__upcoming",
              getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                .days < 0 && "LongTermJobCard__upcoming--started",
            )}
          >
            {getTimeBetweenDates(new Date(), new Date(job.job_start_time))
              .days >= 0
              ? getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                  .days === 0
                ? t("jobs.longTermJobCard.upcomingTextOne")
                : getTimeBetweenDates(new Date(), new Date(job.job_start_time))
                      .days === 1
                  ? t("jobs.longTermJobCard.upcomingTextTwo")
                  : `${t("jobs.longTermJobCard.upcomingTextThree")} ${
                      getTimeBetweenDates(
                        new Date(),
                        new Date(job.job_start_time),
                      ).days
                    } ${t("jobs.longTermJobCard.upcomingTextFour")}`
              : `${selectedLanguage === "SQ" ? "Puna tashmë ka filluar" : "Job has already started"}`}
          </div>
        ) : null}
      </div>
      <div className="LongTermJobCard__schedule">
        <div className="LongTermJobCard__daysSchedule">
          {daysArray.map((day, index) => (
            <div className="LongTermJobCard__daySchedule" key={index}>
              <div
                className={cs(
                  "LongTermJobCard__daySchedule-head",
                  job?.schedule?.shift_days?.map(
                    (dayIndexes: number) =>
                      dayIndexes == index &&
                      "LongTermJobCard__daySchedule-head-active",
                  ),
                )}
              >
                {day}
              </div>
              <div className="LongTermJobCard__daySchedule-body">
                {job?.schedule?.shift_days?.map(
                  (dayIndexes: number) =>
                    dayIndexes == index && (
                      <>
                        <span>
                          {job?.schedule?.start_time?.substring(0, 5)}
                        </span>{" "}
                        <br />
                        <span>
                          {job?.schedule?.end_time?.substring(0, 5)}
                        </span>{" "}
                      </>
                    ),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="LongTermJobCard__applicants-wrapper">
        <div className="LongTermJobCard__applicants">
          <img
            src={person}
            alt="person icon"
            className="LongTermJobCard__person-img"
          />
          {job.status === "assigned" && job.applications_count === 0 && (
            <p> {t("jobs.longTermJobCard.workers.textOne")}</p>
          )}
          {job.status === "assigned" && job.applications_count !== 0 && (
            <p>
              {job.applications_count}{" "}
              {t("jobs.longTermJobCard.workers.textTwo")}
            </p>
          )}
          {job.status === "available" && job.applications_count === 0 && (
            <p> {t("jobs.longTermJobCard.applicants.textOne")}</p>
          )}
          {job.status === "available" && job.applications_count !== 0 && (
            <p className="ShortTermJobCard__applicants-text">
              {job.applications_count}{" "}
              {t("jobs.longTermJobCard.applicants.textTwo")}
            </p>
          )}
        </div>
      </div>
      {job.status === "available" && job?.applications_count === 0 && (
        <Button
          onClick={() => {
            navigate("/jobs/edit-long-term-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }}
          className="LongTermJobCard__edit-button"
          label={t("jobs.longTermJobCard.edit")}
        />
      )}
      {job.status === "available" && job?.applications_count > 0 && (
        <Button
          onClick={() => {
            navigate("/jobs/edit-long-term-job");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }}
          className="LongTermJobCard__edit-button"
          label={t("jobs.longTermJobCard.view")}
        />
      )}
      <Button
        onClick={() => {
          if (job.status === "available") {
            navigate("/jobs/long-term-applicants");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          } else {
            navigate("/jobs/long-term-attendance");
            localStorage.setItem("selectedJob", JSON.stringify(job));
          }
        }}
        className={cs("LongTermJobCard__show-btn")}
        label={t(
          job.status === "available"
            ? "jobs.longTermJobCard.show"
            : "jobs.longTermJobCard.showWorkers",
        )}
      />
    </section>
  );
};

export default LongTermJobCard;
