import React, {useEffect, useState, useRef} from "react";
import {apiCall} from "../../../../api/Api";
import ApplicationCard from "../ApplicationCard/ApplicationCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../../../components/shared/LoadingComponent/LoadingComponent";
import RightSidebar from "../../../../components/shared/RightSidebar/RightSidebar";
import ApplicantSidebar from "../ApplicantSidebar/ApplicantSidebar";
import chevronLeft from "../../../../assets/svg/chevron-down-gray.svg";
import cs from "classnames";
import {useNavigate} from "react-router-dom";
import reloadIcon from "../../../../assets/svg/Refresh_icon.svg";

import {useTranslation} from "react-i18next";

import "./LongTermApplications.scss";
import {useOutsideClickDetection} from "../../../../lib/hooks/useOutSideClickDetection";
import 'mdui/mdui.css';
import 'mdui';
import {Button, Chip, Stack} from "@mui/material";
import {Close, Done} from "@mui/icons-material";

const LongTermApplications = () => {
    let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
    let selectedLanguage = localStorage.getItem("lang");
    const [job, setJob] = useState(jobStorage);
    const [allApplications, setAllApplications] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error] = useState("");
    const [nextPage, setNextPage] = useState<any>("");
    const [isCalled, setIsCalled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openRightSidebar, setOpenSidebar] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState<any>({});
    const [refreshCalled, setRefreshCalled] = useState(false);
    const rightSidebarRef = useRef(null);
    const {t} = useTranslation("common");
    const navigate = useNavigate();
    let applicantFilters = new Map<string, string>(
        [
            [t("jobs.applications.all"), ''],
            [t("jobs.applications.accepted"), 'accepted'],
            [t("jobs.applications.favorite"), 'favourite'],
            [t("jobs.applications.canceled"), 'cancelled']
        ]
    );
    const [selectedFilter, setSelectedFilter] = useState<string>('');

    const hideRightSidebar = () => {
        setOpenSidebar(false);
        setSelectedApplicant({});
    };

    useEffect(() => {
        setLoading(true);
        refresh();
    }, [selectedFilter]);

    useEffect(() => {
        if (!isCalled) {
            setIsLoading(true);
            apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}`)
                .then((res) => {
                    setAllApplications(res.data.results);
                    setNextPage(res.data?.next);
                    setIsCalled(true);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    }, []);

    const refresh = () => {
        apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}`)
            .then((res) => {
                setAllApplications(res.data.results);
                setNextPage(res.data?.next);
                setIsCalled(true);
                setIsLoading(false);
                setLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const loadMore = () => {
        if (nextPage !== null) {
            setIsLoading(true);
            let resultUrl = nextPage.split("?")[1];
            apiCall("GET", `b2b/applications/${job.id}/?status=${selectedFilter}?${resultUrl}`)
                .then((res) => {
                    setAllApplications([...allApplications, ...res.data.results]);
                    setIsCalled(true);
                    setNextPage(res.data?.next);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage: nextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: "0px 0px 400px 0px",
    });

    useOutsideClickDetection(rightSidebarRef, () => {
        hideRightSidebar();
    });

    return (
        <section className="LongTermApplications">
            <div className="LongTermApplications__header">
                <div className="LongTermApplications__title-container">
                    <h1 className="LongTermApplications__default-title">
                        {t("jobs.title")}
                    </h1>
                    {isLoading ? (
                        <div className="Jobs__spinner"/>
                    ) : (
                        <img
                            src={reloadIcon}
                            alt="reload icon"
                            className={cs(
                                "LongTermApplications__reload-icon",
                                refreshCalled && "LongTermApplications__reload-icon--disable",
                            )}
                            onClick={() => {
                                if (!refreshCalled) {
                                    refresh();
                                    setRefreshCalled(true);
                                    setTimeout(() => {
                                        setRefreshCalled(false);
                                    }, 10000);
                                }
                            }}
                        />
                    )}
                </div>
                <section className="LongTermApplications__container">
                    <div
                        onClick={() => navigate("/jobs/short-term-not-assigned")}
                        className={cs("LongTermApplications__container-item")}
                    >
                        {t("jobs.shortTermTabLabel")}
                    </div>
                    <div
                        onClick={() => {
                            navigate("/jobs/long-term-not-assigned");
                        }}
                        className={cs(
                            "LongTermApplications__container-item",
                            "LongTermApplications__container-item--active",
                        )}
                    >
                        {t("jobs.longTermTabLabel")}
                    </div>
                </section>
                <div className="LongTermApplications__header-chips">
                    <Stack direction="row" spacing={1}>
                        {Array.from(applicantFilters).map(([key, value]) => (
                            <Chip
                                key={key}
                                label={key}
                                icon={selectedFilter === value ? <Done/> : undefined}
                                clickable
                                onClick={() => {
                                    setSelectedFilter(value);  // Set state
                                    console.log("Clicked value:", value);  // Logs the clicked chip's value
                                }}
                                variant={selectedFilter === value ? "filled" : "outlined"}
                                sx={{borderRadius: 2}}
                            />
                        ))}
                    </Stack>
                </div>

                <div className="LongTermApplications__header-wrapper">
                    <img
                        alt="chevron left icon"
                        src={chevronLeft}
                        className="LongTermApplications__back-icon"
                        onClick={() => navigate(-1)}
                    />
                    <h2 className="LongTermApplications__title">
                        {selectedLanguage === "SQ"
                            ? `${job.job_title.title?.sq}`
                            : `${job.job_title.title?.en}`}
                    </h2>
                </div>
            </div>
            {!isLoading && allApplications?.length === 0 && (
                <div className="LongTermApplications__no-content">
                    {t("jobs.applications.noContent")}
                </div>
            )}
            <div className="LongTermApplications__cards-wrapper">
                {allApplications &&
                    allApplications.map((person: any, index: number) => (
                        <div key={index} className="Applications__card">
                            <ApplicationCard
                                person={person}
                                jobName={
                                    selectedLanguage === "SQ"
                                        ? job.job_title.title?.sq
                                        : job.job_title.title?.en
                                }
                                setSelectedApplicant={setSelectedApplicant}
                                setOpenSidebar={setOpenSidebar}
                            />
                        </div>
                    ))}
            </div>
            <div>
                {nextPage && nextPage !== null && (
                    <div ref={sentryRef}>
                        <LoadingComponent/>
                    </div>
                )}
            </div>
            {isLoading && !loading && allApplications?.length === 0 && (
                <div className="LongTermApplications__no-content">
                    <LoadingComponent/>
                </div>
            )}
            {openRightSidebar && (
                <div>
                    <RightSidebar
                        onClose={() => hideRightSidebar()}
                        sidebarRef={rightSidebarRef}
                    >
                        <ApplicantSidebar
                            person={selectedApplicant}
                            jobName={
                                selectedLanguage === "SQ"
                                    ? job.job_title.title?.sq
                                    : job.job_title.title?.en
                            }
                            jobId={job.id}
                            onClose={() => hideRightSidebar()}
                            loadMore={refresh}
                        />
                    </RightSidebar>
                </div>
            )}
        </section>
    );
};

export default LongTermApplications;
