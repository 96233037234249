import React, { useState, useEffect } from "react";
import Button from "../../../components/shared/Button/Button";
import { useEditLongTermJobFormik } from "./useEditLongTermJobFormik";
import InputField from "../../../components/shared/InputField/InputField";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import DeleteModal from "../../../components/shared/DeleteModal/DeleteModal";
import { useGlobalContext } from "../../../context/GlobalContext/GlobalContext";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../../lib/helpers/handleErrors";
import { apiCall } from "../../../api/Api";
import cs from "classnames";
import { PhotoProvider, PhotoView } from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";
import info from "../../../assets/svg/info.svg";
import LongTermPicker from "../../../components/shared/LongTermPicker/LongTermPicker";
import fullTimeIcon from "../../../assets/svg/full-time.svg";
import rotativeIcon from "../../../assets/svg/rotative.svg";
import partTimeMorningIcon from "../../../assets/svg/part-time-morning.svg";
import partTimeAfternoonIcon from "../../../assets/svg/part-time-afternoon.svg";
import partTimeNightIcon from "../../../assets/svg/part-time-night.svg";

import "./EditLongTermJob.scss";
import Tooltip from "@mui/material/Tooltip";

interface LongTermPickerDataType {
  job_start_time: string;
  schedule: {
    start_time: string;
    end_time: string;
    shift_days: number[];
    length_in_months: number;
  };
}

const EditLongTermJob = () => {
  const { t } = useTranslation("common");
  let selectedLanguage = localStorage.getItem("lang");
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  const [updateJob, setUpdateJob] = useState<any>(jobStorage);
  const [openDeleteModal2, setOpenDeleteModal2] = useState(false);
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Images, setBase64Images] = useState<File[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [benefits, setBenefits] = useState<null | []>(null);
  const [benefitsLimit, setBenefitsLimit] = useState(5);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
  const [benefitsCounts, setBenefitsCounts] = useState(0);
  const [selectedJobType, setSelectedJobType] = useState<string[]>([]);
  const [longTermPickerData, setLongTermPickerData] =
    useState<null | LongTermPickerDataType>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [allImages, setAllImages] = useState(jobStorage?.image_urls);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  const jobTypeItems = [
    {
      label: t("jobs.newLongTermJob.jobType.fullTime"),
      value: "full_time",
      icon: fullTimeIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.rotative"),
      value: "rotative",
      icon: rotativeIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeMorning"),
      value: "part_time_morning",
      icon: partTimeMorningIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeAfternoon"),
      value: "part_time_afternoon",
      icon: partTimeAfternoonIcon,
    },
    {
      label: t("jobs.newLongTermJob.jobType.partTimeEvening"),
      value: "part_time_night",
      icon: partTimeNightIcon,
    },
  ];

  const formik = useEditLongTermJobFormik({
    onSubmit: async (values: any) => {
      setIsButtonLoading(true);

      let data = {
        ...(values.description.length > 0 && {
          description: values.description,
        }),
        // ...(base64Images.length > 0 && {
        //   image_data: base64Images,
        // }),
        ...(values.seats.length > 0 && {
          seats: values.seats,
        }),
        ...(selectedBenefits.length > 0 && { benefits: selectedBenefits }),
        ...(longTermPickerData?.job_start_time && {
          job_start_time: longTermPickerData?.job_start_time,
        }),

        schedule: {
          ...(longTermPickerData?.schedule.start_time !== "" && {
            start_time: longTermPickerData?.schedule.start_time,
          }),
          ...(longTermPickerData?.schedule.end_time !== "" && {
            end_time: longTermPickerData?.schedule.end_time,
          }),
          ...(longTermPickerData?.schedule.shift_days &&
            longTermPickerData?.schedule.shift_days.length > 0 && {
              shift_days: longTermPickerData?.schedule.shift_days,
            }),
          ...(formik.values.minSalary !== "" && {
            monthly_pay_rate_start: formik.values.minSalary,
          }),
          ...(formik.values.maxSalary !== "" && {
            monthly_pay_rate_end: formik.values.maxSalary,
          }),
          type: selectedJobType,
          length_in_months: longTermPickerData?.schedule.length_in_months,
        },
      };

      apiCall("PUT", `b2b/long-term-jobs/${updateJob.id}/`, data)
        .then((res) => {
          setUpdateJob(res.data);
          globalCtx.openMessageModal(
            "success",
            t("staticMessage.jobUpdatedSuccess"),
          );
          navigate(-1);
        })
        .catch((err) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    },
  });

  useEffect(() => {
    apiCall("GET", `job-benefits/?limit=${benefitsLimit}&offset=0`)
      .then((res) => {
        setBenefits(res.data.results);
        setBenefitsCounts(res.data.count);
      })
      .catch((err) => {});
  }, [benefitsLimit]);

  const handleSelectBenefit = (item: any) => {
    if (selectedBenefits?.includes(item.id)) {
      // Remove the benefit if it's already selected
      setSelectedBenefits((prev) =>
        prev.filter((benefitId) => benefitId !== item.id),
      );
    } else {
      // Add the benefit if it's not selected
      setSelectedBenefits((prev) => [...prev, item.id]);
    }
  };

  const handleSelectJobType = (item: any) => {
    if (selectedJobType?.includes(item.value)) {
      setSelectedJobType((prev) =>
        prev.filter((jobType) => jobType !== item.value),
      );
    } else {
      setSelectedJobType((prev) => [...prev, item.value]);
    }
  };

  const handleDelete = () => {
    apiCall("DELETE", `b2b/long-term-jobs/${updateJob.id}`)
      .then((res) => {
        globalCtx.openMessageModal(
          "success",
          t("staticMessage.jobDeletedSuccess"),
        );
        navigate(-1);
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(
          err?.response?.data?.code,
          t,
        );
        globalCtx.openMessageModal("error", errorMessage);
      });
  };

  useEffect(() => {
    updateJob?.schedule?.monthly_pay_rate_start &&
      formik.setFieldValue(
        "minSalary",
        updateJob?.schedule?.monthly_pay_rate_start,
      );
    updateJob?.schedule?.monthly_pay_rate_end &&
      formik.setFieldValue(
        "maxSalary",
        updateJob?.schedule?.monthly_pay_rate_end,
      );
    formik.setFieldValue("description", updateJob?.description);
    formik.setFieldValue("seats", updateJob?.seats);

    if (updateJob?.schedule?.type?.length > 0) {
      const matchingJobTypes = jobTypeItems
        .filter((item) => updateJob?.schedule?.type.includes(item.value))
        .map((item: any) => item.value);
      setSelectedJobType(matchingJobTypes);
    }

    if (jobStorage.benefits) {
      let benefitsIdFromLocal: string[] = [];
      jobStorage.benefits.map((item: any) => {
        benefitsIdFromLocal.push(item.id);
      });

      setSelectedBenefits(benefitsIdFromLocal);
    }
  }, []);

  return (
    <section className="EditLongTermJob">
      <div className="EditLongTermJob__header">
        <h1 className="EditLongTermJob__default-title">{t("jobs.title")}</h1>
        <div className="EditLongTermJob__header-wrapper">
          <img
            alt="chevron left icon"
            src={chevronLeft}
            className="EditLongTermJob__back-icon"
            onClick={() => navigate(-1)}
          />
          <h2 className="EditLongTermJob__title">
            {selectedLanguage === "SQ"
              ? `${updateJob?.job_title?.title.sq}`
              : `${updateJob?.job_title?.title.en}`}
          </h2>
        </div>
      </div>
      <div className="EditLongTermJob__form">
        <div className="EditLongTermJob__column">
          <div className="EditLongTermJob__subtitle">
            {t("jobs.newLongTermJob.jobDetails.title")}
          </div>

          <div className="EditLongTermJob__item-flex">
            <div className="EditLongTermJob__item">
              <p className="EditLongTermJob__label">
                {t("jobs.jobDetails.jobTitle.label")}
              </p>
              <p className="EditLongTermJob__value">
                {selectedLanguage === "SQ"
                  ? `${updateJob?.job_title?.title.sq}`
                  : `${updateJob?.job_title?.title.en}`}
              </p>
            </div>
          </div>
          <div className="EditLongTermJob__input-container-row">
            <div className="EditLongTermJob__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.minSalary.placeholder",
                )}
                name="minSalary"
                value={formik.values.minSalary}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="EditLongTermJob__input"
              />
              {formik.errors.minSalary && formik.touched.minSalary && (
                <p className="EditLongTermJob__input-error">
                  {formik.errors.minSalary as string}
                </p>
              )}
            </div>{" "}
            <div className="EditLongTermJob__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.newLongTermJob.jobDetails.maxSalary.placeholder",
                )}
                name="maxSalary"
                value={formik.values.maxSalary}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="EditLongTermJob__input"
              />
              {formik.errors.maxSalary && formik.touched.maxSalary && (
                <p className="EditLongTermJob__input-error">
                  {formik.errors.maxSalary as string}
                </p>
              )}

              <div className="EditLongTermJob__input-hint">
                <img
                  src={info}
                  alt="info icon"
                  className="EditLongTermJob__input-hint-icon"
                />
                <div className="EditLongTermJob__input-hint-label">
                  {t("jobs.newLongTermJob.jobDetails.maxSalary.hint")}
                </div>
              </div>
            </div>
          </div>
          <div className="EditLongTermJob__input-container">
            <InputField
              type="number"
              placeholder={t(
                "jobs.newLongTermJob.jobDetails.seats.placeholder",
              )}
              name="seats"
              value={formik.values.seats}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              className="EditLongTermJob__input"
              min="1"
              max="100"
            />
            {formik.errors.seats && formik.touched.seats && (
              <p className="EditLongTermJob__input-error">
                {formik.errors.seats as string}
              </p>
            )}
          </div>
          <div className="EditLongTermJob__item">
            <p className="EditLongTermJob__label">
              {t("jobs.jobDetails.businessUnit.label")}
            </p>
            <p className="EditLongTermJob__value">
              {updateJob?.organization_unit?.city}
            </p>
          </div>
          <div className="EditLongTermJob__input-container">
            <TextAreaInput
              placeholder={t(
                "jobs.newLongTermJob.jobDetails.description.placeholder",
              )}
              name="description"
              value={formik.values.description}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              className="EditLongTermJob__textarea"
            />
            {formik.errors.description && formik.touched.description && (
              <p className="EditLongTermJob__input-error">
                {formik.errors.description as string}
              </p>
            )}
          </div>
          <div className="EditLongTermJob__images-label-wrapper">
            <div className="EditLongTermJob__images-label">
              {t("jobs.newLongTermJob.jobDetails.images.title")}
            </div>
            <img
              src={info}
              alt="info icon"
              className="EditLongTermJob__info-icon"
            />
            <span className="EditLongTermJob__images-note">
              {" "}
              {t("jobs.newLongTermJob.jobDetails.images.optional")}
            </span>
          </div>
          <PhotoProvider
            maskOpacity={0.7}
            overlayRender={(props) => {
              return (
                <>
                  <SlideArrowLeft {...props} />
                  <SlideArrowRight {...props} />
                  <CloseButton onClick={props.onClose} />
                </>
              );
            }}
          >
            <div className="EditLongTermJob__images">
              {updateJob?.image_urls?.length !== 0 &&
                updateJob?.image_urls?.map((item: any, index: number) => (
                  <div
                    className="EditLongTermJob__image-preview-container"
                    key={index}
                  >
                    <PhotoView src={item} key={index}>
                      <img
                        alt="image preview"
                        src={item}
                        className="EditLongTermJob__image-preview"
                        style={{
                          width: "92px",
                          height: "92px",
                          objectFit: "cover",
                          borderRadius: "6px",
                        }}
                      />
                    </PhotoView>
                    <img
                      alt="delete icon"
                      src={deleteIcon}
                      className="EditLongTermJob__delete-icon"
                      onClick={() => {
                        setIsLoading(true);
                        apiCall("DELETE", `b2b/jobs/${updateJob.id}/image/`, {
                          image_urls: [item],
                        })
                          .then((res) => {
                            setUpdateJob(res.data);
                            setIsLoading(false);
                          })
                          .catch((err) => {
                            setIsLoading(false);
                          });
                        setOpenDeleteModal(false);
                      }}
                    />
                  </div>
                ))}
              {updateJob?.image_urls.length < 10 && (
                <div
                  onClick={() => setIsImageUploaderOpen(true)}
                  className="EditLongTermJob__images-placeholder"
                >
                  <img
                    src={closeGray}
                    alt="plus icon"
                    className="EditLongTermJob__add-icon"
                  />
                </div>
              )}
            </div>
          </PhotoProvider>

          {isImageUploaderOpen && (
            <MultiFileUploader
              accept="image/*"
              onCancel={() => {
                setIsImageUploaderOpen(false);
              }}
              onSubmit={(images) => {
                setIsLoading(true);
                let data = {
                  image_data: images,
                };

                apiCall("PUT", `b2b/jobs/${updateJob.id}/image/`, data)
                  .then((res) => {
                    setUpdateJob(res.data);
                    setIsLoading(false);
                  })
                  .catch((err) => {
                    setIsLoading(false);
                  });
                setIsImageUploaderOpen(false);
              }}
              allImages={updateJob?.image_urls}
              maxImageSize={10485760}
            />
          )}
        </div>
        <div className="EditLongTermJob__column">
          <div className="EditLongTermJob__shift-label">
            {t("jobs.newLongTermJob.jobSchedule.title")}
          </div>
          <LongTermPicker
            sendBackData={(data) => {
              setLongTermPickerData(data);
            }}
          />

          <div className="EditLongTermJob__jobType">
            <div className="EditLongTermJob__jobType-title-container">
              <div className="EditLongTermJob__jobType-title">
                {t("jobs.newLongTermJob.jobType.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="EditLongTermJob__info-icon"
              />
              <span className="EditLongTermJob__images-note">
                {t("jobs.newLongTermJob.jobType.hint")}
              </span>
            </div>
            <div className="EditLongTermJob__jobType-body">
              {jobTypeItems.map((item, index) => (
                <div
                  className={cs(
                    "JobTypeCart",
                    selectedJobType.includes(item.value) &&
                      "JobTypeCart--active",
                  )}
                  key={index}
                  onClick={() => {
                    handleSelectJobType(item);
                  }}
                >
                  <img
                    alt="pic"
                    src={item.icon}
                    className="JobTypeCart__icon"
                  />
                  <div className="JobTypeCart__label">{item.label}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="EditLongTermJob__jobBenefits">
            <div className="EditLongTermJob__jobBenefits-title-container">
              <div className="EditLongTermJob__jobBenefits-title">
                {" "}
                {t("jobs.newLongTermJob.jobBenefits.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="EditLongTermJob__info-icon"
              />
              <span className="EditLongTermJob__images-note">
                {""}
                {t("jobs.newLongTermJob.jobDetails.images.optional")}
              </span>
            </div>

            <div className="EditLongTermJob__jobBenefits-body">
              {benefits?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={cs(
                    "JobBenefitsCart",
                    selectedBenefits.includes(item.id) &&
                      "JobBenefitsCart--active",
                  )}
                  onClick={() => {
                    handleSelectBenefit(item);
                  }}
                >
                  <img
                    alt="pic"
                    src={item.icon}
                    className="JobBenefitsCart__icon"
                  />
                  <div className="JobBenefitsCart__label">
                    {selectedLanguage === "SQ" ? item.title.sq : item.title.en}
                  </div>
                </div>
              ))}
            </div>
            <div className="EditLongTermJob__jobBenefits-footer">
              {benefitsLimit > 5 && (
                <div
                  className="EditLongTermJob__jobBenefits-viewLess"
                  onClick={() => {
                    if (benefitsLimit > 5) {
                      setBenefitsLimit(benefitsLimit - 5);
                    }
                  }}
                >
                  {t("jobs.newLongTermJob.jobBenefits.viewLess")}
                </div>
              )}

              {benefitsLimit < benefitsCounts && (
                <div
                  className="EditLongTermJob__jobBenefits-viewMore"
                  onClick={() => {
                    if (benefitsLimit < benefitsCounts) {
                      setBenefitsLimit(benefitsLimit + 5);
                    }
                  }}
                >
                  {t("jobs.newLongTermJob.jobBenefits.viewMore")}
                </div>
              )}
            </div>
          </div>
          {errorMessage !== "" && (
            <h5 className="EditLongTermJob__errorMessage">{errorMessage}</h5>
          )}
        </div>
      </div>

      <div className="EditLongTermJob__buttons-wrapper">
        <Button
          onClick={() => setOpenDeleteModal2(true)}
          className="Button__error EditLongTermJob__delete-button"
          label={t("jobs.jobDetails.delete")}
        />
        {openDeleteModal2 && (
          <DeleteModal
            name={`${updateJob?.job_title?.title.en}`}
            onCancel={() => setOpenDeleteModal2(false)}
            onSubmit={() => {
              handleDelete();
              setOpenDeleteModal2(false);
            }}
          />
        )}
        <Tooltip
          title={
            updateJob.applications_count > 0
              ? t("jobs.newLongTermJob.saveBtnTooltip")
              : ""
          }
          placement="top"
        >
          <div className="EditLongTermJob__buttons">
            {/*<Button*/}
            {/*  type="reset"*/}
            {/*  className="Button__secondary  EditLongTermJob__cancel-btn"*/}
            {/*  label={t("jobs.jobDetails.cancel")}*/}
            {/*  onClick={() => {*/}
            {/*    navigate("/jobs/long-term-not-assigned");*/}
            {/*  }}*/}
            {/*/>*/}
            <Button
              isLoading={isButtonLoading}
              type="submit"
              label={t("jobs.jobDetails.submit")}
              onClick={() => {
                formik.handleSubmit();
              }}
              className="EditLongTermJob__submit-btn"
              disabled={isButtonLoading || updateJob.applications_count > 0}
            />
          </div>
        </Tooltip>
      </div>
    </section>
  );
};

export default EditLongTermJob;
